<template>
  <div class="page">
    <div class="page-main">
      <div class="steps">
        <kxjl-steps :active="active" :steps="steps" />
      </div>
      <el-form class="page-card"
        v-if="active == 0"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item :label="$t('group.groupName')" prop="groupName">
          <el-input
            v-model="form.groupName"
            :placeholder="$t('group.groupNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('group.groupDesc')" prop="groupDesc">
          <el-input
            v-model="form.groupDesc"
            :placeholder="$t('group.groupDescPla')"
            :maxlength="500"
            type="textarea"
            :rows="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button class="footer-btn"
            type="primary"
            plain
            @click="$router.go(-1)"
          >{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn"
            type="primary"
            @click="next()"
          >{{ $t('nextStep') }}</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-if="active == 1"
        ref="form2"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="deviceType">
          <el-radio-group v-model="form.deviceType">
            <el-radio :label="1">{{ $t('task.selectDevice') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <select-device
            ref="selectDevice"
            :macs.sync="form.macs"
            :devices.sync="form.devices"
            :deviceType.sync="form.deviceType"
            :toolsDisplay="{
              siteShow: true,
              modelShow: true,
              keywordsShow: true,
              groupShow: false,
              siteDisable: false,
              modelDisable: false,
              keywordsDisable: false,
              groupDisable: false,
            }"
          ></select-device>
        </el-form-item>
        <p class="support">{{ $t('supportCopy') }}</p>
        <el-form-item class="footer-btns table-btn">
          <el-button class="footer-btn"
            type="primary"
            plain
            @click="$router.go(-1)"
          >{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn"
            type="primary"
            plain
            @click="active = 0"
          >{{ $t('lastStep') }}</el-button>
          <el-button class="footer-btn"
            type="primary"
            @click="save()"
          >{{ $t('nextStep') }}</el-button>
        </el-form-item>
      </el-form>
      <set-param
        v-if="active == 2"
        relationName="group"
        :relationId="relationId"
      />
    </div>
  </div>
</template>

<script>
import { groupQuery, groupAdd, groupUpdate, groupNameVerify } from '@/api/group'
import { SelectDevice, SetParam, KxjlSteps } from '@/components'

export default {
  components: {
    SelectDevice,
    SetParam,
    KxjlSteps
  },
  data() {
    return {
      active: 0,
      steps: [
        this.$t('groupSteps.basicInformation'),
        this.$t('groupSteps.groupDevice'),
        this.$t('groupSteps.setParameters'),
        // this.$t('groupSteps.finish'),
      ],
      oldGroupName: '',
      form: {
        groupName: '',
        groupDesc: '',
        deviceType: 1,
        devices: [],
        macs: [],
      },
      rules: {
        groupName: {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error(this.$t('group.groupNameRule'),));
            } else {
              if(this.oldGroupName != value) {
                groupNameVerify({groupName: value}).then(({ data }) => {
                  if(data.message == 'ok') {
                    callback();
                  }else{
                    callback(new Error(data.message));
                  }
                })
              } else {
                callback();
              }
            }
          },
          trigger: 'submit',
        },
      },
      relationId: null,
    }
  },
  watch: {
    'form.devices'(val) {
      this.form.macs = val.map((e) => e.macAddress)
    },
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
  },
  methods: {
    getData(id) {
      groupQuery({ id })
        .then(({ data }) => {
          const { id, groupName, groupDesc, deviceType, devices } = data.result.rows[0]
          this.form.id = id
          this.form.groupName = groupName
          this.oldGroupName = groupName
          this.form.groupDesc = groupDesc
          this.form.deviceType = deviceType
          this.form.devices = devices
        })
        .catch(() => {})
    },
    next() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.active = 1
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    save() {
      this.$refs['form2'].validate((valid) => {
        if (this.active == 1 && !this.form.devices.length > 0) {
          this.$message.error(this.$t('group.groupDerviceErr'))
        } else if (valid) {
          const saveApi = this.$route.query.id ? groupUpdate : groupAdd
          saveApi(this.form)
            .then(({ data }) => {
              this.active +=1
              this.relationId = this.$route.query.id || data.result.rows[0]
              // this.$message.success(data.message)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 0;
  .page-card {
    padding: 0 140px;
  }
  .support {
    padding: 0 30px;
    color: #999;
    font-size: 14px;
    text-align: center;
  }
  .el-radio-group {
    padding: 0 30px;
  }
  .table-btn {
    padding: 39px 140px;
  }
}
</style>