var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-main"},[_c('div',{staticClass:"steps"},[_c('kxjl-steps',{attrs:{"active":_vm.active,"steps":_vm.steps}})],1),(_vm.active == 0)?_c('el-form',{ref:"form",staticClass:"page-card",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"auto"}},[_c('el-form-item',{attrs:{"label":_vm.$t('group.groupName'),"prop":"groupName"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('group.groupNamePla'),"maxlength":128},model:{value:(_vm.form.groupName),callback:function ($$v) {_vm.$set(_vm.form, "groupName", $$v)},expression:"form.groupName"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('group.groupDesc'),"prop":"groupDesc"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('group.groupDescPla'),"maxlength":500,"type":"textarea","rows":6},model:{value:(_vm.form.groupDesc),callback:function ($$v) {_vm.$set(_vm.form, "groupDesc", $$v)},expression:"form.groupDesc"}})],1),_c('el-form-item',{staticClass:"footer-btns"},[_c('el-button',{staticClass:"footer-btn",attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('el-button',{staticClass:"footer-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.next()}}},[_vm._v(_vm._s(_vm.$t('nextStep')))])],1)],1):_vm._e(),(_vm.active == 1)?_c('el-form',{ref:"form2",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"deviceType"}},[_c('el-radio-group',{model:{value:(_vm.form.deviceType),callback:function ($$v) {_vm.$set(_vm.form, "deviceType", $$v)},expression:"form.deviceType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v(_vm._s(_vm.$t('task.selectDevice')))])],1)],1),_c('el-form-item',[_c('select-device',{ref:"selectDevice",attrs:{"macs":_vm.form.macs,"devices":_vm.form.devices,"deviceType":_vm.form.deviceType,"toolsDisplay":{
            siteShow: true,
            modelShow: true,
            keywordsShow: true,
            groupShow: false,
            siteDisable: false,
            modelDisable: false,
            keywordsDisable: false,
            groupDisable: false,
          }},on:{"update:macs":function($event){return _vm.$set(_vm.form, "macs", $event)},"update:devices":function($event){return _vm.$set(_vm.form, "devices", $event)},"update:deviceType":function($event){return _vm.$set(_vm.form, "deviceType", $event)},"update:device-type":function($event){return _vm.$set(_vm.form, "deviceType", $event)}}})],1),_c('p',{staticClass:"support"},[_vm._v(_vm._s(_vm.$t('supportCopy')))]),_c('el-form-item',{staticClass:"footer-btns table-btn"},[_c('el-button',{staticClass:"footer-btn",attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('el-button',{staticClass:"footer-btn",attrs:{"type":"primary","plain":""},on:{"click":function($event){_vm.active = 0}}},[_vm._v(_vm._s(_vm.$t('lastStep')))]),_c('el-button',{staticClass:"footer-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('nextStep')))])],1)],1):_vm._e(),(_vm.active == 2)?_c('set-param',{attrs:{"relationName":"group","relationId":_vm.relationId}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }