<template>
  <div class="page">
    <div class="page-main">
      <div class="steps">
        <kxjl-steps :active="active" :steps="steps" />
      </div>
      <el-form
        class="page-card"
        ref="form"
        v-if="active == 0"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item :label="$t('site.site')" prop="siteId">
          <SelectSite
            v-if="siteData.length"
            :treeData="siteData"
            v-model="form.siteId"
            checkStrictly
          />
        </el-form-item>
        <el-form-item :label="$t('site.description')">
          <el-input
            v-model="form.siteDesc"
            :placeholder="$t('site.descriptionPla')"
            :maxlength="500"
            type="textarea"
            :rows="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button
            class="footer-btn"
            type="primary"
            plain
            @click="$router.go(-1)"
          >{{ $t('cancel') }}</el-button>
          <el-button
            class="footer-btn"
            type="primary"
            @click="submit"
          >{{ $t('nextStep') }}</el-button>
        </el-form-item>
      </el-form>
      <set-param
        v-if="active == 1"
        relationName="site"
        :relationId="relationId"
      />
    </div>
  </div>
</template>

<script>
import { siteAdd, siteUpdate, siteQuery, siteTempSiteSelect } from '@/api/site'
import { SetParam, SelectSite, KxjlSteps } from '@/components'

export default {
  name: 'SiteDetails',
  components: { SetParam, SelectSite, KxjlSteps },
  data() {
    return {
      active: 0,
      steps: [
        this.$t('siteSteps.basic'),
        this.$t('siteSteps.setParameters'),
        // this.$t('siteSteps.finish'),
      ],
      form: {
        siteId: '',
        siteDesc: '',
      },
      rules: {
        siteId: {
          required: true,
          message: this.$t('site.siteRule'),
          trigger: 'blur',
        },
      },
      siteData: [],
    }
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
    this.getSiteData()
  },
  methods: {
    getData(id) {
      siteQuery({ id })
        .then(({ data }) => {
          const { id, siteId, siteDesc } = data.result.rows[0]
          this.$set(this, 'form', {
            id,
            siteId,
            siteDesc,
          })
        })
        .catch(() => {})
    },
    getSiteData() {
      siteTempSiteSelect()
        .then(({ data }) => {
          this.siteData = data.result.rows
        })
        .catch(() => {})
    },
    getSiteName(id, siteList) {
      for (let site of siteList) {
        console.log(id, site)
        if (site.id == id) {
          return site
        } else if (site.childSite.length) {
          let findSite = this.getSiteName(id, site.childSite)
          if (findSite) return findSite
        }
      }
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const submitApi = this.$route.query.id ? siteUpdate : siteAdd
          submitApi({
            ...this.form,
            siteName: this.getSiteName(this.form.siteId, this.siteData).siteName
          })
            .then(({ data }) => {
              // this.$router.push({
              //   path: '/site/setParam',
              //   query: {
              //     relationName: 'site',
              //     id: this.$route.query.id ? this.$route.query.id : data.result.rows[0],
              //   },
              // })
              this.active +=1
              this.relationId = this.$route.query.id || data.result.rows[0]
              // this.$message.success(data.message)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
</style>